body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.errorMsg {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ant-card {
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
}

.ant-collapse-header {
  color: red;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: red;
  /* background-color: #e90f87; */
  font-size: 17px;
  font-weight: bold;
  color: rgb(111, 10, 10);
  /* border-radius: 50px; */
}

.ant-collapse-content > .ant-collapse-content-box {
  background-color: #ffffff;
  /* border-radius: 20px; */
  color: #363636;
}

.swal-wide {
  width: 450px;
  color: black;
  height: 400px;
}

.container {
  margin-top: 15px;
}

.comment {
  display: block;
  transition: all 1s;
}

.commentClicked {
  min-height: 0px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px 10px;
}

.ant-list-items {
  cursor: pointer;
  transition: ease-in-out 3s;
  /* margin: 100px; */
  padding: 20px;
  margin: 10px;
}

.ant-list-item-meta-avatar {
  font-size: 18px;
}

.listname {
  color: #025bba;
  font-weight: 500;
}

.listdescription {
  font-size: 18px;
  color: #505050;
}

.descriptionView {
  font-size: 16px;
  text-align: justify;
}

.ant-list-items :hover {
  /* transform: scale(1.01);
  transition: ease-in-out 3s; */
  background-color: rgb(230, 235, 255);
  border-radius: 1px;
  /* background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%); */
  /* color: crimson; */
}

.containerpic {
  position: relative;
  text-align: center;
  /* color: black; */
  max-height: 400px;
  max-width: 400px;
  margin-bottom: 0px;
  z-index: 1;
}

.centeredpic {
  position: absolute;
  top: 50%;
  left: 35%;
  max-height: 400px;

  /* transform: translate(-50%, -50%); */
  z-index: 2;
}

.ant-row .ant-form-item {
  color: crimson;
}

.itemimgdisplay:hover {
  transform: scale(4);
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.descriptionView {
  color: black;
  font-size: 15px;
}
